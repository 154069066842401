import { RefObject, useEffect, useState } from "react";

type MousePosition = {
  x: number | null;
  y: number | null;
};

type UseMousePositionProps = (ref: RefObject<HTMLDivElement>) => MousePosition;

const useMousePosition: UseMousePositionProps = (ref) => {
  const [mousePosition, setMousePosition] = useState<MousePosition>({
    x: null,
    y: null,
  });

  useEffect(() => {
    const currentRef = ref.current;

    const updateMousePosition = (e: MouseEvent) => {
      const rect = currentRef?.getBoundingClientRect();

      if (rect) {
        setMousePosition({ x: e.clientX - rect.x, y: e.clientY - rect.y });
      } else {
        setMousePosition({ x: null, y: null });
      }
    };

    currentRef?.addEventListener("mousemove", updateMousePosition);

    return () => {
      currentRef?.removeEventListener("mousemove", updateMousePosition);
    };
  }, [ref]);

  return mousePosition;
};
export default useMousePosition;
