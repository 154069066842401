import { ReactNode, useEffect, useRef, useState } from "react";
import classNames from "classnames";

import useMousePosition from "@hooks/use-mouse-position";
import { useIsMobile } from "@hooks/use-window-size";

const tooltipWidth = 220;

interface Props {
  text: string;
  enabled: boolean;
  children: ReactNode;
  showBelowCursor?: boolean;
}

const FloatingTooltipWrapper: React.FC<Props> = ({
  text,
  enabled,
  children,
  showBelowCursor = false,
}) => {
  const tooltipContainerRef = useRef<HTMLDivElement>(null);
  const isMobile = useIsMobile();

  const { x: mouseX, y: mouseY } = useMousePosition(tooltipContainerRef);

  const [canShowTooltip, setCanShowTooltip] = useState(false);

  const shouldShowTooltip = enabled && canShowTooltip;

  useEffect(() => {
    if (!isMobile) return;

    const timer = setTimeout(() => {
      setCanShowTooltip(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, [canShowTooltip, isMobile]);

  return (
    <div
      ref={tooltipContainerRef}
      className="relative"
      onClick={() => setCanShowTooltip(true)}
      onMouseEnter={() => setCanShowTooltip(true)}
      onMouseLeave={() => setCanShowTooltip(false)}
    >
      <div
        className={classNames(isMobile && shouldShowTooltip && "opacity-30")}
      >
        {children}
      </div>

      {shouldShowTooltip && (
        <div
          className="absolute text-background bg-foreground px-2 py-1 rounded-md text-sm font-normal"
          style={{
            width: tooltipWidth,
            bottom:
              !isMobile && mouseY !== null
                ? `calc(100% - ${mouseY}px ${
                    showBelowCursor ? "- 70px" : "+ 10px"
                  })`
                : "50%",
            left:
              !isMobile && mouseX !== null
                ? `${mouseX - tooltipWidth / 2}px`
                : "50%",
            transform: isMobile ? "translateX(-50%) translateY(50%)" : "none",
          }}
        >
          {text}
        </div>
      )}
    </div>
  );
};

export default FloatingTooltipWrapper;
