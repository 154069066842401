import { FC, ReactNode, useState } from "react";
import classNames from "classnames";

import { IconType } from "@lib/shared-types";

import HeaderButtons, { HeaderButtonType } from "@components/App/HeaderButtons";
import { Button } from "@components/Button";
import ChevronLeftIcon from "@components/Icons/ChevronLeftIcon";
import CloseIcon from "@components/Icons/CloseIcon";
import Spinner from "@components/LoadingSpinner";
import Modal, { ModalProps } from "@components/Modals/Modal";
import UnsavedChangesModal from "@components/Modals/UnsavedChangesModal";

export interface BigModalProps extends ModalProps {
  title?: string;
  customTitle?: ReactNode;
  description?: string;
  descriptionIcon?: IconType;
  onActionText?: string;
  onAction?: (value: any) => void | Promise<void>;
  isDirty?: boolean;
  onActionLoading?: boolean;
  isActionDisabled?: boolean;
  removeBodyStyling?: boolean;
  className?: string;
  contentClassName?: string;
  disableClose?: boolean;
  useBackIcon?: boolean;
  headerButtons?: HeaderButtonType[];
  isLoading?: boolean;
  hideHeader?: boolean;
}

const BigModal: FC<BigModalProps> = ({
  trigger,
  show,
  toggleShow,
  title,
  customTitle,
  description,
  descriptionIcon: DescriptionIcon,
  onActionText,
  onAction,
  isActionDisabled,
  onActionLoading,
  isDirty,
  children,
  removeBodyStyling = false,
  className,
  contentClassName,
  disableClose = false,
  useBackIcon = false,
  isLoading = false,
  hideHeader = false,
  headerButtons,
  ...rest
}) => {
  const [showUnsavedChanges, setShowUnsavedChanges] = useState<boolean>(false);
  const toggleShowUnsavedChanges = () =>
    setShowUnsavedChanges(!showUnsavedChanges);

  const handleClose = () => toggleShow && toggleShow(false);

  return (
    <Modal
      size="big"
      className={className}
      trigger={trigger}
      show={show}
      toggleShow={toggleShow}
      onInteractOutside={isDirty ? toggleShowUnsavedChanges : undefined}
      onEscapeKeyDown={isDirty ? toggleShowUnsavedChanges : undefined}
      {...rest}
    >
      {!hideHeader && (
        <div className="h-[96px] flex items-center justify-between px-6 border-b gap-6">
          <div className="flex items-center gap-6">
            {!disableClose && (
              <>
                {isDirty ? (
                  <Button
                    icon={useBackIcon ? <ChevronLeftIcon /> : <CloseIcon />}
                    square
                    onClick={toggleShowUnsavedChanges}
                  />
                ) : (
                  <Button
                    icon={useBackIcon ? <ChevronLeftIcon /> : <CloseIcon />}
                    square
                    onClick={handleClose}
                  />
                )}
                <UnsavedChangesModal
                  show={showUnsavedChanges}
                  toggleShow={toggleShowUnsavedChanges}
                  onDiscard={() => toggleShow?.(false)}
                />
              </>
            )}
            {customTitle || (
              <div className="flex-1">
                <h3 className="text-2xl text-black-ink leading-tight">
                  {title}
                </h3>
                {(description || DescriptionIcon) && (
                  <div className="text-grey-500 hidden sm:flex items-center mt-1 gap-2">
                    {DescriptionIcon && <DescriptionIcon className="w-5 h-5" />}
                    <p className="text-sm leading-5">{description}</p>
                  </div>
                )}
              </div>
            )}
          </div>
          {headerButtons && <HeaderButtons headerButtons={headerButtons} />}
          {onActionText && (
            <Button
              primary
              onClick={onAction}
              isLoading={onActionLoading}
              disabled={isActionDisabled}
            >
              {onActionText}
            </Button>
          )}
        </div>
      )}
      <div
        className={classNames(
          !removeBodyStyling && "p-6 overflow-y-auto max-h-[600px]",
          contentClassName
        )}
      >
        {isLoading ? (
          <div className="flex items-center justify-center p-4">
            <Spinner />
          </div>
        ) : (
          children
        )}
      </div>
    </Modal>
  );
};

export default BigModal;
