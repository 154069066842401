import { FC } from "react";
import { FormProvider, useForm } from "react-hook-form";

import TextAreaForm from "@components/Form/TextAreaForm";
import BlockIcon from "@components/Icons/BlockIcon";
import ClockIcon from "@components/Icons/ClockIcon";
import SmallModal from "@components/Modals/SmallModal";

interface ConfirmRescheduleCancelAppointmentModalProps {
  action: "reschedule" | "cancel";
  onAction: (reason?: string) => void;
  toggleShow: () => void;
  show: boolean;
  reasonRequired?: boolean;
}

type FieldTypes = {
  description: string;
};

const ConfirmRescheduleCancelAppointmentModal: FC<
  ConfirmRescheduleCancelAppointmentModalProps
> = ({ action, onAction, toggleShow, show, reasonRequired = false }) => {
  const actionInfo = {
    reschedule: {
      icon: ClockIcon,
      iconColor: "action",
      title: "Reschedule appointment?",
      label: "Reschedule",
      actionText: "Confirm reschedule",
    },
    cancel: {
      icon: BlockIcon,
      iconColor: "peach",
      title: "Cancel appointment?",
      label: "Cancel",
      actionText: "Confirm cancellation",
    },
  };

  const icon = actionInfo[action].icon;
  const iconColor = actionInfo[action].iconColor;
  const title = actionInfo[action].title;
  const label = actionInfo[action].label;
  const actionText = actionInfo[action].actionText;

  const formMethods = useForm<FieldTypes>();

  const {
    handleSubmit,
    formState: { errors },
    register,
    watch,
  } = formMethods;

  const description = watch("description");
  const isActionDisabled =
    reasonRequired && (!description || description?.length === 0);

  const onSubmit = async (data: FieldTypes) => {
    const { description } = data;

    onAction(description);
  };

  return (
    <SmallModal
      show={show}
      toggleShow={toggleShow}
      icon={icon}
      iconColor={iconColor as any}
      title={title}
      onActionText={actionText}
      onAction={handleSubmit(onSubmit)}
      isActionDisabled={isActionDisabled}
      disableClose
      showCancel
    >
      <div className="mt-6">
        <FormProvider {...formMethods}>
          <TextAreaForm
            name="description"
            register={register}
            label={`${label} reason`}
            errors={errors}
            required={reasonRequired}
          />
        </FormProvider>
      </div>
    </SmallModal>
  );
};

export default ConfirmRescheduleCancelAppointmentModal;
