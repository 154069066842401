import { FC } from "react";
import TextareaAutosize from "react-textarea-autosize";
import classNames from "classnames";

import InfoIcon from "@components/Icons/InfoIcon";
import FloatingTooltipWrapper from "@components/Tooltips/FloatingTooltipWrapper";
import { Tooltip, TooltipSize } from "@components/Tooltips/Tooltip";

interface TextAreaFormProps {
  containerClassName?: string;
  textClassName?: string;
  label?: string;
  name: string;
  placeholder?: string;
  register?: any;
  required?: boolean;
  errors?: any;
  errorMessage?: string;
  disabled?: boolean;
  tooltip?: string;
  tooltipSize?: TooltipSize;
  disabledTooltip?: string;
  defaultValue?: string;
  minRows?: number;
  maxRows?: number;
  readOnly?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  value?: string;
}

const TextAreaForm: FC<TextAreaFormProps> = ({
  containerClassName,
  textClassName,
  label,
  name,
  placeholder,
  register,
  required,
  errors,
  errorMessage,
  disabled = false,
  tooltip,
  tooltipSize,
  disabledTooltip,
  defaultValue,
  minRows = 2,
  maxRows,
  readOnly,
  onChange,
  value,
}) => {
  const isError = required && errors[name];
  const textAreaClassName = classNames(
    "mt-1 form-input block w-full py-2 px-3 border rounded-md",
    isError &&
      "border-peach-600 text-red-900 focus:border-red-300 focus:ring-red",
    !isError &&
      !disabled &&
      !readOnly &&
      "border-grey-900 hover:bg-grey-950 hover:border-grey-950 focus:outline-none focus:shadow-none focus:bg-white focus:border-action-700 transition duration-150 ease-in-out focus:ring-0",
    (disabled || readOnly) &&
      "bg-grey-950 text-grey-800 border-grey-900 focus:border-grey-950 focus:ring-0",
    textClassName
  );

  const renderTooltip = tooltip && (
    <Tooltip
      className="inline-block ml-2 text-grey-500 hover:text-black-ink"
      trigger={<InfoIcon />}
      size={tooltipSize}
    >
      {tooltip}
    </Tooltip>
  );

  const renderTextarea = (
    <TextareaAutosize
      id={label}
      minRows={minRows}
      maxRows={maxRows}
      title={label}
      defaultValue={defaultValue}
      name={name}
      placeholder={placeholder}
      {...(register &&
        register(name, {
          required: { value: required, message: errorMessage },
        }))}
      disabled={disabled}
      className={textAreaClassName}
      readOnly={readOnly}
      {...(onChange && { onChange })}
      value={value}
    />
  );

  return (
    <div className={containerClassName}>
      <label
        htmlFor={label}
        className="flex items-center text-sm leading-5 text-grey-500"
      >
        <span className="flex-1">{label}</span>
        {renderTooltip}
      </label>

      {disabledTooltip ? (
        <FloatingTooltipWrapper text={disabledTooltip} enabled={disabled}>
          {renderTextarea}
        </FloatingTooltipWrapper>
      ) : (
        renderTextarea
      )}

      {errors && errors[name] && errors[name].message && (
        <div className="mt-2 p-3 text-sm rounded-md text-peach-600 bg-peach-950 flex flex-row items-center">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12.8716 5.54947C12.4893 4.86982 11.5107 4.86982 11.1284 5.54947L3.83825 18.5097C3.46329 19.1763 3.945 20 4.70983 20H19.2901C20.055 20 20.5367 19.1763 20.1617 18.5097L12.8716 5.54947ZM11 10.5C11 10.2239 11.2238 10 11.5 10H12.5C12.7761 10 13 10.2239 13 10.5V14.5C13 14.7761 12.7761 15 12.5 15H11.5C11.2238 15 11 14.7761 11 14.5V10.5ZM11 17C11 16.4477 11.4477 16 12 16C12.5523 16 13 16.4477 13 17C13 17.5523 12.5523 18 12 18C11.4477 18 11 17.5523 11 17Z"
            />
          </svg>
          <p id="email-error">{errors[name]?.message}</p>
        </div>
      )}
    </div>
  );
};

export default TextAreaForm;
