import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const ChevronLeftIcon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      fill="none"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M8.77822 12.364L14.8389 18.4247C15.0341 18.6199 15.0341 18.9365 14.8389 19.1318L14.1318 19.8389C13.9365 20.0341 13.6199 20.0341 13.4247 19.8389L6.3536 12.7678C6.24317 12.6574 6.1952 12.5081 6.20967 12.364C6.19519 12.2199 6.24317 12.0706 6.3536 11.9602L13.4247 4.88912C13.6199 4.69386 13.9365 4.69386 14.1318 4.88912L14.8389 5.59623C15.0341 5.79149 15.0341 6.10807 14.8389 6.30334L8.77822 12.364Z" />
    </svg>
  );
};

export default ChevronLeftIcon;
