import { useEffect, useState } from "react";
import resolveConfig from "tailwindcss/resolveConfig";

import tailwindConfig from "../tailwind.config";

// https://stackoverflow.com/questions/63406435/how-to-detect-window-size-in-next-js-ssr-using-react-hook
export default function useWindowSize() {
  const [windowSize, setWindowSize] = useState<{
    width: number | undefined;
    height: number | undefined;
  }>({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    if (typeof window !== "undefined") {
      const handleResize = () => {
        setWindowSize({ width: window.innerWidth, height: window.innerHeight });
      };

      window.addEventListener("resize", handleResize);

      handleResize();

      return () => window.removeEventListener("resize", handleResize);
    }
  }, []);

  return windowSize;
}

export function useIsMobile() {
  const { width } = useWindowSize();
  return width && width < 640;
}

/*
 * Check the screen size based on the tailwindConfig
 */
// @ts-ignore - withOpacity() returns a function for some of our colors. But typescript wants a string. Is this fine?
const { theme } = resolveConfig(tailwindConfig);
export function useCheckScreenSize() {
  const checkSize = (value) => {
    return (
      typeof window !== "undefined" &&
      theme?.screens &&
      window.matchMedia(`(min-width: ${theme.screens[value]})`).matches
    );
  };

  return {
    isMD: checkSize("md"),
    isLG: checkSize("lg"),
  };
}
