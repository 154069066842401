import React from "react";
import { twMerge } from "tailwind-merge";

const styles = {
  grey: {
    selected: "bg-grey-950 text-grey-500",
    default: "bg-grey-950 text-grey-500",
  },
  action: {
    selected: "bg-action-600 text-black-ink",
    default: "bg-grey-950 text-grey-500",
  },
  green: {
    selected: "bg-green-600 text-green-100",
    default: "bg-green-900 text-green-100",
  },
  white: {
    selected: "bg-white text-grey-150",
    default: "bg-white text-grey-150",
  },
  peach: {
    selected: "bg-peach-600 text-white",
    default: "bg-peach-600 text-white",
  },
  blue: {
    selected: "bg-blue-300 text-white",
    default: "bg-blue-300 text-white",
  },
  "dark-blue": {
    selected: "bg-blue-100 text-white",
    default: "bg-blue-100 text-white",
  },
  "light-action": {
    selected: "bg-action-300/10 text-action-300",
    default: "bg-action-300/10 text-action-300",
  },
  dark: {
    selected: "bg-grey-950/10 text-white",
    default: "bg-grey-950/10 text-white",
  },
  "dark-grey": {
    selected: "bg-grey-900 text-grey-500",
    default: "bg-grey-900 text-grey-500",
  },
  "white-transparent": {
    selected: "bg-white/20 text-white",
    default: "bg-white/20 text-white",
  },
};

export type TagColorType = keyof typeof styles;

interface TagProps {
  className?: string;
  children: React.ReactNode;
  isSelected?: boolean;
  color?: TagColorType;
  isMd?: boolean;
  isInvisible?: boolean;
}

const Tag: React.FC<TagProps> = ({
  className,
  children,
  isSelected,
  color = "green",
  isMd,
  isInvisible,
}) => {
  if (!children && !isInvisible) return <></>;
  return (
    <div
      className={twMerge(
        "font-medium rounded-lg px-2 py-0.5 flex items-center justify-center",
        isInvisible && "invisible",
        isSelected ? styles[color].selected : styles[color].default,
        isMd ? "text-sm" : "text-xs",
        className
      )}
    >
      {children}
    </div>
  );
};

export default Tag;
