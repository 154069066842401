import React, { FC } from "react";

import { SVGIconProps } from "@lib/shared-types";

const ProBadgeIcon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={className}
      width="48"
      height="24"
      viewBox="0 0 48 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="47.21"
        height="24"
        rx="6"
        fill="url(#paint0_radial_8201_1021)"
      />
      <rect
        width="47.21"
        height="24"
        rx="6"
        fill="url(#paint1_radial_8201_1021)"
        fillOpacity="0.05"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.0951 12.4771V11.8354C41.0951 10.953 40.9774 10.1643 40.7421 9.46908C40.5122 8.76855 40.1833 8.17229 39.7555 7.68031C39.3277 7.18299 38.817 6.80598 38.2234 6.5493C37.6298 6.28727 36.9721 6.15625 36.2502 6.15625C35.5336 6.15625 34.8785 6.28727 34.2849 6.5493C33.6967 6.80598 33.186 7.18299 32.7528 7.68031C32.325 8.17229 31.9935 8.76855 31.7582 9.46908C31.5229 10.1643 31.4052 10.953 31.4052 11.8354V12.4771C31.4052 13.3595 31.5229 14.1509 31.7582 14.8514C31.9988 15.552 32.3357 16.1482 32.7689 16.6402C33.202 17.1322 33.7127 17.5092 34.301 17.7712C34.8945 18.0279 35.5496 18.1562 36.2662 18.1562C36.9881 18.1562 37.6459 18.0279 38.2395 17.7712C38.8331 17.5092 39.3411 17.1322 39.7635 16.6402C40.1913 16.1482 40.5202 15.552 40.7502 14.8514C40.9801 14.1509 41.0951 13.3595 41.0951 12.4771ZM39.0817 11.8194V12.4771C39.0817 13.1295 39.0175 13.7071 38.8892 14.2097C38.7662 14.7124 38.5844 15.1375 38.3437 15.4851C38.1031 15.8274 37.809 16.0867 37.4614 16.2632C37.1138 16.4343 36.7154 16.5199 36.2662 16.5199C35.8224 16.5199 35.4266 16.4343 35.079 16.2632C34.7314 16.0867 34.432 15.8274 34.1806 15.4851C33.9347 15.1375 33.7475 14.7124 33.6191 14.2097C33.4908 13.7071 33.4266 13.1295 33.4266 12.4771V11.8194C33.4266 11.1669 33.4908 10.5921 33.6191 10.0948C33.7475 9.59208 33.932 9.17229 34.1726 8.83539C34.4186 8.49315 34.7154 8.23646 35.063 8.06534C35.4106 7.88887 35.8063 7.80063 36.2502 7.80063C36.694 7.80063 37.0924 7.88887 37.4454 8.06534C37.7983 8.23646 38.0951 8.49315 38.3357 8.83539C38.5817 9.17229 38.7662 9.59208 38.8892 10.0948C39.0175 10.5921 39.0817 11.1669 39.0817 11.8194Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.4358 13.6402H9.01337V17.9958H7V6.31668H11.4358C12.3396 6.31668 13.107 6.47711 13.738 6.79796C14.3743 7.11347 14.8583 7.55197 15.1898 8.11347C15.5214 8.66962 15.6872 9.30598 15.6872 10.0226C15.6872 10.7766 15.5214 11.4236 15.1898 11.9637C14.8583 12.5038 14.3743 12.9183 13.738 13.2071C13.107 13.4958 12.3396 13.6402 11.4358 13.6402ZM9.01337 7.92096V12.044H11.4358C11.9652 12.044 12.393 11.9584 12.7193 11.7873C13.0455 11.6161 13.2834 11.3808 13.4332 11.0814C13.5882 10.7766 13.6658 10.429 13.6658 10.0386C13.6658 9.66962 13.5882 9.3247 13.4332 9.00384C13.2834 8.67764 13.0455 8.41561 12.7193 8.21775C12.393 8.01989 11.9652 7.92096 11.4358 7.92096H9.01337Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.4633 6.31668H23.5943C24.482 6.31668 25.2387 6.45037 25.8644 6.71775C26.4901 6.98513 26.9687 7.38085 27.3002 7.90491C27.6371 8.42363 27.8056 9.06534 27.8056 9.83005C27.8056 10.4129 27.6986 10.9263 27.4847 11.3702C27.2708 11.814 26.9687 12.1883 26.5783 12.4931C26.3049 12.7029 25.9948 12.8798 25.6479 13.024L28.2788 17.8915V17.9958H26.1291L23.7717 13.4878H21.4767V17.9958H19.4633V6.31668ZM23.6264 11.8915H21.4767V7.92096H23.5943C24.097 7.92096 24.5114 8.00384 24.8376 8.16962C25.1639 8.33005 25.4045 8.56267 25.5596 8.86748C25.72 9.16694 25.8002 9.51989 25.8002 9.9263C25.8002 10.3006 25.7173 10.6375 25.5516 10.937C25.3911 11.2311 25.1505 11.4637 24.8296 11.6349C24.5088 11.806 24.1077 11.8915 23.6264 11.8915Z"
        fill="white"
      />
      <rect
        x="0.5"
        y="0.5"
        width="46.21"
        height="23"
        rx="5.5"
        stroke="url(#paint2_linear_8201_1021)"
        strokeOpacity="0.5"
      />
      <defs>
        <radialGradient
          id="paint0_radial_8201_1021"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(13.8176 24) rotate(-54.0009) scale(51.9143 89.5124)"
        >
          <stop stopColor="#061632" />
          <stop offset="0.726362" stopColor="#002566" />
        </radialGradient>
        <radialGradient
          id="paint1_radial_8201_1021"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(8.06024 32.7692) rotate(-56.1113) scale(36.1393 63.1911)"
        >
          <stop offset="0.766173" />
          <stop offset="0.76838" stopColor="white" />
        </radialGradient>
        <linearGradient
          id="paint2_linear_8201_1021"
          x1="23.605"
          y1="0"
          x2="23.605"
          y2="16.6154"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4C70AE" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default ProBadgeIcon;
