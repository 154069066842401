import React, { FC } from "react";

import { SVGIconProps } from "@lib/shared-types";

const TeamsBadgeIcon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="74"
      height="24"
      fill="none"
      viewBox="0 0 74 24"
    >
      <rect
        width="73.444"
        height="24"
        fill="url(#paint0_radial_8640_46967)"
        rx="6"
      ></rect>
      <rect
        width="73.444"
        height="24"
        fill="url(#paint1_radial_8640_46967)"
        fillOpacity="0.1"
        rx="6"
      ></rect>
      <path
        fill="#76410A"
        d="M65.31 14.19c.075.187.113.4.113.642 0 .315-.08.593-.24.834-.161.24-.402.428-.723.561-.315.134-.708.2-1.179.2a4.09 4.09 0 01-1.01-.12 2.486 2.486 0 01-.819-.368 1.789 1.789 0 01-.553-.658c-.129-.268-.193-.591-.193-.97h-2.013c0 .62.13 1.162.393 1.627.262.46.615.845 1.059 1.156.444.304.935.532 1.476.681.545.15 1.099.225 1.66.225.626 0 1.193-.072 1.7-.217.514-.15.953-.36 1.316-.633.37-.278.653-.613.85-1.003.198-.396.297-.84.297-1.331 0-.471-.08-.89-.24-1.26a2.905 2.905 0 00-.722-1.002 4.88 4.88 0 00-1.204-.787 11.04 11.04 0 00-1.692-.641 9.883 9.883 0 01-1.115-.385 4.086 4.086 0 01-.786-.425 1.679 1.679 0 01-.465-.498 1.212 1.212 0 01-.153-.601c0-.31.078-.589.233-.835.155-.246.39-.44.706-.585.315-.145.71-.217 1.187-.217.503 0 .917.086 1.243.257.332.171.578.406.738.706.166.294.249.633.249 1.019h1.997c0-.653-.171-1.25-.513-1.79-.337-.54-.821-.97-1.452-1.29C64.829 6.16 64.083 6 63.217 6c-.615 0-1.177.08-1.685.24-.508.156-.949.378-1.323.666-.37.284-.655.62-.858 1.011-.198.39-.297.824-.297 1.3 0 .48.096.906.289 1.275.192.364.462.687.81.97.347.279.754.525 1.219.739.47.208.984.398 1.54.57.508.154.925.306 1.251.456.332.145.589.294.77.45.182.155.308.326.377.513zM11.631 7.765V17.84H9.634V7.765H6V6.16h9.297v1.605H11.63zM26.453 16.243v1.597h-7.645V6.16h7.605v1.605h-5.591v3.272h4.82v1.573h-4.82v3.633h5.63z"
      ></path>
      <path
        fill="#76410A"
        fillRule="evenodd"
        d="M35.242 6.16l4.412 11.68h-2.118l-.945-2.736H32.07l-.943 2.736h-2.11l4.396-11.68h1.83zm-2.622 7.348h3.42l-1.712-4.955-1.708 4.955z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#76410A"
        d="M45.548 6.16l3.377 9.008 3.369-9.008h2.623v11.68h-2.014v-3.883l.199-5.207-3.471 9.09h-1.428L44.74 8.755l.198 5.202v3.883h-2.005V6.16h2.615z"
      ></path>
      <rect
        width="72.444"
        height="23"
        x="0.5"
        y="0.5"
        stroke="url(#paint2_linear_8640_46967)"
        strokeOpacity="0.5"
        rx="5.5"
      ></rect>
      <defs>
        <radialGradient
          id="paint0_radial_8640_46967"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(47.47003 -42 75.57854 85.4218 21.496 24)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CC8033"></stop>
          <stop offset="0.726" stopColor="#FFDA9A"></stop>
        </radialGradient>
        <radialGradient
          id="paint1_radial_8640_46967"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="rotate(-42.265 48.66 .164) scale(53.1832 98.1954)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.667" stopColor="#F3B36A"></stop>
          <stop offset="0.667" stopColor="#fff"></stop>
        </radialGradient>
        <linearGradient
          id="paint2_linear_8640_46967"
          x1="36.722"
          x2="36.722"
          y1="0"
          y2="16.615"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FAD89E"></stop>
          <stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
};

export default TeamsBadgeIcon;
