import { cloneElement, FC, ReactElement, useState } from "react";
import Link from "next/link";

import { Button, ButtonProps } from "@components/Button";
import {
  Tooltip,
  TooltipKind,
  TooltipSize,
  TooltipVariant,
} from "@components/Tooltips/Tooltip";

interface ModalTriggerProps {
  renderButton: (onClick?: ActionButtonProps["onClick"]) => JSX.Element;
  modal: NonNullable<ActionButtonProps["modal"]>;
}

const ModalTrigger: FC<ModalTriggerProps> = ({ renderButton, modal }) => {
  const [show, setShow] = useState(false);
  const toggleShow = () => setShow(!show);

  return (
    <>
      {renderButton(toggleShow)}
      {cloneElement(modal, {
        show,
        toggleShow,
      })}
    </>
  );
};

export interface ActionButtonProps extends ButtonProps {
  text: string;
  mobileText?: string;
  isIconButton?: boolean;
  modal?: ReactElement;
  href?: string;
  target?: "_blank";
  heapEventName?: string;
  tooltipVariant?: TooltipVariant;
}

const ActionButton: FC<ActionButtonProps> = ({
  text,
  mobileText = text,
  isIconButton = false,
  modal,
  href,
  target,
  heapEventName,
  tooltipVariant = TooltipVariant.theme,
  ...rest
}) => {
  const renderButton = (onClick?: ActionButtonProps["onClick"]) => (
    <Button
      onClick={onClick}
      square={isIconButton}
      aria-label={text}
      data-heap-event-name={heapEventName}
      {...rest}
    >
      {!isIconButton && text && (
        <>
          <span className="hidden sm:block">{text}</span>
          <span className="sm:hidden">{mobileText}</span>
        </>
      )}
    </Button>
  );

  const renderContent = rest?.disabled ? (
    renderButton()
  ) : modal ? (
    <ModalTrigger renderButton={renderButton} modal={modal} />
  ) : href && target === "_blank" ? (
    <Link href={href} passHref target="_blank">
      {renderButton()}
    </Link>
  ) : href ? (
    <Link href={href} legacyBehavior>
      {renderButton()}
    </Link>
  ) : (
    renderButton(rest?.onClick)
  );

  return isIconButton ? (
    <Tooltip
      trigger={renderContent}
      size={TooltipSize.small}
      placement="bottom"
      kind={TooltipKind.button}
      variant={tooltipVariant}
    >
      {text}
    </Tooltip>
  ) : (
    renderContent
  );
};

export default ActionButton;
