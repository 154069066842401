import { FC, Fragment } from "react";
import classNames from "classnames";

import { ArtifactType, LayoutVariantType } from "@lib/shared-types";

import ActionButton, {
  ActionButtonProps,
} from "@components/Buttons/ActionButton";
import { DropdownMenu, DropdownMenuItem } from "@components/DropdownMenu";
import BlockIcon from "@components/Icons/BlockIcon";
import MoreIcon from "@components/Icons/MoreIcon";
import TrashIcon from "@components/Icons/TrashIcon";
import CancelAppointmentModal from "@components/Modals/CancelAppointmentModal";
import CancelModal from "@components/Modals/CancelModal";
import DeleteModal from "@components/Modals/DeleteModal";

interface HeaderDropdownType {
  text: string;
  options: Array<ActionButtonProps>;
}

interface HeaderDeleteButton {
  onDelete: () => void;
  onRecurringDelete?: () => void;
  onUndoDelete?: (val: string) => void;
  undoStatus?: string;
  artifactType: ArtifactType;
}

interface HeaderCancelButton {
  onCancel: () => void;
  artifactType: ArtifactType;
  disabled?: boolean;
}

interface HeaderCancelAppointmentButton extends HeaderCancelButton {
  showOutcomeModal?: boolean;
  reasonRequired?: boolean;
}

export type HeaderButtonType =
  | ActionButtonProps
  | HeaderDropdownType
  | HeaderDeleteButton
  | HeaderCancelButton
  | HeaderCancelAppointmentButton;

interface Props {
  headerButtons: HeaderButtonType[];
  variant?: LayoutVariantType;
  showOnlyDropdownOnHeader?: boolean;
}

const HeaderButtons: FC<Props> = ({
  headerButtons,
  variant = "default",
  showOnlyDropdownOnHeader = false,
}) => {
  if (!headerButtons?.length) return null;

  let primaryHeaderButton: HeaderButtonType | null = null;
  if ((headerButtons[headerButtons.length - 1] as ActionButtonProps).primary) {
    primaryHeaderButton = headerButtons[headerButtons.length - 1];
    headerButtons = headerButtons.slice(0, headerButtons.length - 1);
  }

  if (showOnlyDropdownOnHeader) primaryHeaderButton = headerButtons[0];

  const getHeapName = (
    prefix: string,
    item: HeaderDeleteButton | HeaderCancelButton
  ) => [prefix, item?.artifactType, "modal"].join("_");

  headerButtons.forEach((button, i) => {
    if ((button as HeaderDeleteButton)?.onDelete)
      headerButtons[i] = {
        icon: <TrashIcon />,
        isIconButton: true,
        text: "Delete",
        modal: <DeleteModal {...button} />,
        heapEventName: getHeapName("open_delete", button as HeaderDeleteButton),
      };
    else if ((button as HeaderCancelAppointmentButton)?.onCancel)
      headerButtons[i] = {
        icon: <BlockIcon />,
        isIconButton: true,
        text: "Cancel",
        disabled: button.disabled,
        modal: <CancelAppointmentModal {...button} />,
        heapEventName: getHeapName("open_cancel", button as HeaderCancelButton),
      };
    else if ((button as HeaderCancelButton)?.onCancel)
      headerButtons[i] = {
        icon: <BlockIcon />,
        isIconButton: true,
        text: "Cancel",
        disabled: button.disabled,
        modal: <CancelModal {...button} />,
        heapEventName: getHeapName("open_cancel", button as HeaderCancelButton),
      };
  });

  const buttonShape =
    variant === "main"
      ? "circle"
      : variant === "record"
      ? "square-on-mobile"
      : "square";

  const renderOptions = (key: string) => {
    const buttons = showOnlyDropdownOnHeader
      ? headerButtons.slice(1)
      : headerButtons;

    return buttons?.reduce((previous: React.JSX.Element[], current, index) => {
      if ((current as HeaderDropdownType).options) {
        const dropdown = current as HeaderDropdownType;
        const uniqueKey = `${key}-${index}`;
        previous.push(
          <DropdownMenu
            key={`header-button-key-${uniqueKey}`}
            className="hidden sm:block"
            text={dropdown.text}
            size="medium"
            shadowClass="shadow-lg"
            buttonShape={buttonShape}
          >
            {dropdown.options.map((dropdownOption, i) => (
              <DropdownMenuItem
                key={`dropdown-option-key-${uniqueKey}-${i}`}
                {...dropdownOption}
              />
            ))}
          </DropdownMenu>
        );
        dropdown.options.forEach((dropdownOption, itemIndex) => {
          previous.push(
            <DropdownMenuItem
              key={`header-dropdown-option-key-${key}-${index}-${itemIndex}`}
              className="sm:hidden"
              {...dropdownOption}
            />
          );
          itemIndex++;
        });
      } else {
        const button = current as ActionButtonProps;
        previous.push(
          <Fragment key={`header-dropdown-option-key-${key}-${index}`}>
            {!showOnlyDropdownOnHeader && (
              <ActionButton
                className={classNames({
                  "hidden sm:flex": headerButtons?.length > 1,
                })}
                circle={variant === "main" || variant === "record"}
                {...button}
              />
            )}
            <DropdownMenuItem
              className={classNames({
                "sm:hidden":
                  headerButtons?.length > 1 && !showOnlyDropdownOnHeader,
                hidden: headerButtons?.length === 1,
              })}
              {...button}
            />
          </Fragment>
        );
      }
      return previous;
    }, []);
  };

  return (
    <>
      {showOnlyDropdownOnHeader && (
        <ActionButton circle {...headerButtons[0]} />
      )}
      {headerButtons?.length > 1 && (
        <DropdownMenu
          className={!showOnlyDropdownOnHeader && "sm:hidden"}
          icon={<MoreIcon />}
          size="medium"
          shadowClass="shadow-lg"
          buttonShape={buttonShape}
        >
          {renderOptions("ddm")}
        </DropdownMenu>
      )}

      {!showOnlyDropdownOnHeader && (
        <div
          className={classNames(
            {
              flex: headerButtons?.length === 1,
              "hidden sm:flex": headerButtons?.length > 1,
            },
            "sm:gap-4"
          )}
        >
          {renderOptions("buttons")}
        </div>
      )}
      {!!primaryHeaderButton && !showOnlyDropdownOnHeader && (
        <ActionButton {...primaryHeaderButton} />
      )}
    </>
  );
};

export default HeaderButtons;
