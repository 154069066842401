import InfoIcon from "@components/Icons/InfoIcon";
import SmallModal from "@components/Modals/SmallModal";

type Props = {
  show: boolean;
  toggleShow: (val: boolean) => void;
  onDiscard: (val: void) => void;
};

const UnsavedChangesModal: React.FC<Props> = ({
  show,
  toggleShow,
  onDiscard,
}) => {
  return (
    <SmallModal
      show={show}
      toggleShow={toggleShow}
      disableClose
      icon={InfoIcon}
      iconColor="action"
      title="Discard changes?"
      description="Are you sure you want to discard recent changes?"
      onAction={onDiscard}
      onActionText="Discard"
      heapModalName="unsaved_changes_modal"
    />
  );
};

export default UnsavedChangesModal;
